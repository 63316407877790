<template>
  <div>
    <div v-if="layoutPcMi">
      <div class="classification-goods">
        <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }"
        ><i class="el-icon-s-home"></i
      ></el-breadcrumb-item>
      <el-breadcrumb-item>{{ classifyName }}</el-breadcrumb-item>
    </el-breadcrumb> -->
        <div class="classification-location">
          <div>您的当前位置:</div>
          <div @click="to('/')">首页</div>
          <div>
            {{ !$route.query.id ? "全部礼品" : $route.query.classtitle }}
          </div>
        </div>
        <div class="classification-container">
          <div class="left-list">
            <img
              src="http://shop.shangjiu-gift.com.cn/Templates/14/images/lpfl.jpg"
              alt=""
            />
            <div
              v-for="(item, index) in classificationList"
              :key="index"
              class="left-item"
              @click="queryClassShop(item.id, item.name)"
            >
              <div class="classify-title">
                <img
                  src="http://cdn.weilf.cn/Templates/14/images/product-12.png"
                  alt=""
                />
                <div>{{ item.name }}</div>
              </div>
              <div
                @click.stop="queryClassShop(item2.id, item2.name)"
                v-for="(item2, index2) in item.child"
                class="classify-child"
              >
                <div>> {{ item2.name }}</div>
              </div>
            </div>
          </div>
          <div class="right-content">
            <div class="centerlist">
              <div class="center-screen">
                <div class="center-screen-title">类别:</div>
                <div
                  @click="queryClassShop(''), (classShopActive = 'All')"
                  :style="{ color: classShopActive == 'All' ? '#c51323' : '' }"
                  class="center-screen-item"
                >
                  全部
                </div>
                <div
                  @click="
                    queryClassShop(item.id, item.name),
                      (classShopActive = index)
                  "
                  :style="{ color: classShopActive == index ? '#c51323' : '' }"
                  v-for="(item, index) in classificationList2"
                  class="center-screen-item"
                >
                  {{ item.name }}
                </div>
                <div
                  class="center-screen-item"
                  style="color: #000; font-size: 13px"
                  @click="loadMore"
                >
                  查看更多 <span class="el-icon-arrow-down"></span>
                </div>
              </div>
              <!-- <div class="center-screen">
            <div class="center-screen-title">应用场景:</div>
            <div class="center-screen-item">夏季好礼</div>
            <div class="center-screen-item">中秋节礼品</div>
            <div class="center-screen-item">春节礼品</div>
            <div class="center-screen-item">户外踏青</div>
            <div class="center-screen-item">防暑降温</div>
          </div> -->
              <div class="center-screen">
                <div class="center-screen-title">价格:</div>
                <div
                  @click="priceChange(item.value, index)"
                  v-for="(item, index) in priceScreen"
                  :key="index"
                  class="center-screen-item"
                  :style="{ color: item.checked ? '#c51323' : '' }"
                >
                  {{ item.label }}
                </div>
                <div class="center-screen-price">
                  <input v-model="minPrice" type="text" />
                  <span>-</span>
                  <input v-model="maxPrice" type="text" />
                  <span>元</span>
                  <div @click="priceChange(`${minPrice}-${maxPrice}`, -1)">
                    确定
                  </div>
                </div>
              </div>
              <div class="sort-screen">
                <div class="sort-screen-title">排序:</div>
                <div
                  @click="sortChange(index, item.value)"
                  :style="{ color: item.checked ? '#c51323' : '' }"
                  v-for="(item, index) in sortord"
                  :key="index"
                  class="sort-item"
                >
                  {{ item.label }}
                </div>
              </div>
            </div>

            <div class="paging-content">
              <div @click="resetCondition" class="paging-left">重置条件</div>
              <div class="paging">
                <div
                  :style="{ color: startNum == 0 ? '#c0c4cc' : '' }"
                  @click="pagingBtn('prev')"
                >
                  上一页
                </div>
                <div
                  :style="{
                    color:
                      recommendList.length == 0 && !loading ? '#c0c4cc' : '',
                  }"
                  @click="pagingBtn('next')"
                >
                  下一页
                </div>
              </div>
            </div>

            <!-- <div v-loading="loading" class="product-list"> -->
            <div class="product-list">
              <div
                v-for="(item, index) in recommendList"
                :key="index"
                class="product-item"
                @click="to('/detail?id=' + item.id)"
              >
                <!-- <img v-lazy="item.img" alt="" /> -->
                <img :src="item.img" alt="" />
                <div class="product-content">
                  <div>{{ item.name }}</div>
                  <div>
                    市场价:
                    <span
                      >￥{{ item.spec && item.spec.origin.toFixed(1) }}</span
                    >
                  </div>
                  <div>
                    商城价:
                    <span>￥{{ item.spec && item.spec.price.toFixed(1) }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="recommendList.length == 0 && !loading"
              style="text-align: center; font-size: 12px; margin-top: 20px"
            >
              <div>sorry，没有搜索到您想要的产品哦</div>
              <div>请联系我们客服，把您想要的产品告诉我们吧！</div>
            </div>

            <div
              v-else
              class="paging"
              style="justify-content: center; margin-top: 20px"
            >
              <div
                :style="{ color: startNum == 0 ? '#c0c4cc' : '' }"
                @click="pagingBtn('prev')"
              >
                上一页
              </div>
              <div
                :style="{
                  color: recommendList.length == 0 && !loading ? '#c0c4cc' : '',
                }"
                @click="pagingBtn('next')"
              >
                下一页
              </div>
            </div>
          </div>
        </div>
        <div v-if="false" class="content">
          <div v-if="layoutPcMi" class="left-content">
            <h4>商品分类</h4>
            <!-- <el-tree
          :data="classificationList"
          :props="defaultProps"
          @node-click="handleNodeClick"
        ></el-tree> -->
            <div class="left-menu">
              <div
                @click="menuOpen(index)"
                :style="{ maxHeight: item.checked ? height + 'px' : 20 + 'px' }"
                class="menu-item"
                v-for="(item, index) in classificationList"
                :key="index"
              >
                <div class="menu-item1">
                  <div :style="{ color: item.checked ? 'red' : '' }">
                    <!-- <div
                  class="el-icon-arrow-right"
                  style="margin-right: 10px"
                ></div> -->
                    {{ item.name }}
                  </div>
                  <div v-show="item.child">
                    <div v-show="!item.checked" class="el-icon-arrow-up"></div>
                    <div
                      v-show="item.checked"
                      class="el-icon-arrow-down"
                      style="color: red"
                    ></div>
                  </div>
                </div>

                <div class="menu-item2">
                  <div
                    @click.stop="menuChild(index, index2)"
                    v-for="(item2, index2) in item.child"
                    :key="index2"
                    :style="{ color: item2.checked ? 'red' : '' }"
                  >
                    {{ item2.name }}
                  </div>
                </div>
              </div>
            </div>
            <el-carousel height="400px">
              <el-carousel-item v-for="item in 4" :key="item">
                <h3 class="small">{{ item }}</h3>
              </el-carousel-item>
            </el-carousel>
            <h5>推荐商品</h5>
            <div class="recommendList">
              <div
                v-for="(item, index) in recommendList"
                :key="index"
                class="recommended-goods"
              >
                <img :src="item.img" alt="" />
                <div>
                  <p>{{ item.name }}</p>
                  <p>￥{{ item.price }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="right-content">
            <!-- <h3>当季流行</h3> -->
            <h3>{{ classifyName }}</h3>
            <el-form :inline="true">
              <el-form-item style="width: 23%" label="排序方式">
                <el-select
                  style="width: 100px"
                  v-model="sortVal"
                  placeholder="请选择"
                  @change="sortChange"
                >
                  <el-option
                    v-for="item in sortord"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item style="width: 20%" label="价格">
                <el-select
                  style="width: 100px"
                  v-model="priceVal"
                  placeholder="请选择"
                  @change="priceScreenChange"
                >
                  <el-option
                    v-for="(item, index) in priceScreen"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <div class="screen-price">
                  <div>
                    <el-input v-model="minPrice"></el-input>
                  </div>
                  <span style="margin: 0 10px">-</span>
                  <div>
                    <el-input v-model="maxPrice"></el-input>
                  </div>
                  <span style="margin: 0 10px">元</span>
                </div>
              </el-form-item>
              <!-- <el-button @click="screen">筛选</el-button> -->
              <el-button @click="screen">确定</el-button>
              <el-button @click="rouGoodsCon">对比</el-button>
            </el-form>
            <div
              v-loading="loading"
              :class="layoutPcMi ? 'goods-flex' : 'goods-flex-ml'"
            >
              <div
                v-for="(item, index) in productList"
                :key="index"
                class="good-item"
                @mouseover="goodMouseOver(index)"
                @mouseleave="goodMouseLeave(index)"
                @click="rouGoodDetail(item.id)"
              >
                <div class="img">
                  <div class="img-bg"></div>
                  <img :src="item.img" alt="" />
                  <div class="hover-btn" v-show="item.hover">
                    <el-tooltip
                      effect="dark"
                      content="收藏"
                      placement="top-start"
                    >
                      <div class="good-collect" @click.stop="collect(item.id)">
                        <i class="el-icon-star-off"></i>
                      </div>
                    </el-tooltip>
                    <el-tooltip
                      effect="dark"
                      content="对比"
                      placement="top-start"
                    >
                      <div @click.stop="toContrast(item)" class="good-collect">
                        <i class="el-icon-sort"></i>
                      </div>
                    </el-tooltip>
                  </div>
                </div>
                <div class="evaluation">
                  <el-rate
                    disabled-void-color="#d2d2d2"
                    :value="item.star > 5 ? 5 : item.star"
                    disabled
                  >
                  </el-rate>
                </div>
                <p>{{ item.name }}</p>
                <div class="add-cat">
                  <el-button class="el-button-add-cart" v-show="item.hover"
                    ><i class="el-icon-shopping-cart-full"></i
                    >加入购物车</el-button
                  >
                </div>
                <p class="price">￥{{ item.price }}</p>
              </div>
            </div>

            <div
              v-if="productList.length == 0 && !loading"
              style="text-align: center; margin-top: 100px"
            >
              暂无数据
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <div>
        <navbar-header
          style="position: fixed; top: 0; left: 0; right: 0; z-index: 999"
        ></navbar-header>
        <!-- <div style="position: fixed; top: 0; left: 0; right: 0; z-index: 999">
          {{ aaa }} {{ loading }}
        </div> -->

        <div
          class="h5-category-product"
          style="margin-top: 60px; height: 90vh; overflow: auto"
          v-infinite-scroll="load"
          :infinite-scroll-immediate="false"
          :infinite-scroll-distance="1"
        >
          <!-- <div v-for="i in 110">{{ i }}</div> -->
          <div class="h5-breadcrumb">
            <van-icon @click="to('/')" name="wap-home" />
            <div>/</div>
            <div>
              {{ !$route.query.id ? "全部商品" : $route.query.classtitle }}
            </div>
          </div>
          <div class="h5-screen-type">
            <div
              @click="(pickerShow = true), (pickerType = '1')"
              class="screen-type-item"
            >
              <div class="screen-label">排序方式</div>
              <div class="screen-content">
                <div>{{ sortordName }}</div>
                <van-icon size="12" name="arrow-down" />
              </div>
            </div>
            <div
              @click="(pickerShow = true), (pickerType = '2')"
              class="screen-type-item"
            >
              <div class="screen-label">价格</div>
              <div class="screen-content">
                <div>{{ priceName }}</div>
                <van-icon size="12" name="arrow-down" />
              </div>
            </div>
          </div>

          <div class="h5-product-list">
            <div
              v-for="(item, index) in recommendList"
              :key="index"
              class="h5-product-item"
              @click="rouGoodDetail(item.id)"
            >
              <div class="h5-product-content">
                <img :src="item.img" alt="" />
                <div class="h5-productname">
                  {{ item.name }}
                </div>
                <div class="h5-price">
                  ￥{{ item.spec && item.spec.price.toFixed(1) }}
                  <span>￥{{ item.spec && item.spec.origin.toFixed(1) }}</span>
                </div>
              </div>
            </div>
          </div>

          <div
            style="text-align: center; margin-top: 20px"
            v-if="h5More == 'noMore' && !loading"
          >
            没有更多数据了
          </div>
          <div
            style="text-align: center; margin-top: 20px"
            v-else-if="recommendList.length == 0 && !loading"
          >
            没有搜索到符合条件的商品。
          </div>
        </div>
      </div>
      <van-popup
        v-model="pickerShow"
        position="bottom"
        :style="{ height: '50%' }"
      >
        <van-picker
          title="请选择"
          show-toolbar
          :columns="pickerType == '1' ? columns : columns2"
          @confirm="onConfirm"
          @cancel="onCancel"
          @change="onChange"
          value-key="label"
        />
      </van-popup>
    </div>
  </div>
</template>

<script>
import { http } from "../../../request/http";
import navbarHeader from "../../components/navbarHeader.vue";
import Vue from "vue";
import { Toast } from "vant";

Vue.use(Toast);
export default {
  components: {
    navbarHeader,
  },
  name: "classificationGoods",
  data() {
    return {
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
      classificationList: [],
      classificationList2: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
      hoverBtn: false,
      classifyName: "",
      productList: [], //商品列表
      sortord: [
        {
          value: "",
          label: "默认",
          checked: false,
        },
        {
          value: "price-asc",
          label: "价格低-高",
          checked: false,
        },
        {
          value: "price-desc",
          label: "价格高-低",
          checked: false,
        },
      ], //排序方式
      priceScreen: [
        {
          value: "0-10",
          label: "0-10元",
          checked: false,
        },
        {
          value: "10-20",
          label: "10-20元",
          checked: false,
        },
        {
          value: "20-50",
          label: "20-50元",
          checked: false,
        },
        {
          value: "50-100",
          label: "50-100元",
          checked: false,
        },
        {
          value: "100-200",
          label: "100-200元",
          checked: false,
        },
        {
          value: "300-400",
          label: "300-400元",
          checked: false,
        },
        {
          value: "400-500",
          label: "400-500元",
          checked: false,
        },
        {
          value: "500-999999",
          label: "500元以上",
          checked: false,
        },
      ],

      sortVal: "", //排序
      priceVal: "", //价格筛选的值
      classifyId: "", //当前选中的分类id
      loading: false,
      minPrice: "", //最小价格
      maxPrice: "", // 最大价格
      layoutPcMi: this.$store.state.layoutPcMi,
      recommendList: [],
      menuList: [
        {
          name: "当季流行",
          checked: false,
          children: [
            {
              name: "羊绒大衣",
            },
            {
              name: "羊绒大衣",
            },
          ],
        },
        {
          name: "加厚棉衣",
          checked: false,
          children: [
            {
              name: "牛仔外套",
            },
            {
              name: "韩版",
            },
          ],
        },
      ],
      height: 20,
      goodsTypeId: "", //当前分类id
      requestParams: {}, //请求参数
      classShopActive: "All",
      endSlice: 20,
      startNum: 0,
      getNum: 50,
      loading2: false,
      finished: false,
      refreshing: false,
      list: [],
      h5More: "",
      columns: [
        {
          value: "",
          label: "默认",
        },
        {
          value: "price-asc",
          label: "价格低-高",
        },
        {
          value: "price-desc",
          label: "价格高-低",
        },
      ],
      columns2: [
        {
          value: "0-10",
          label: "0-10元",
        },
        {
          value: "10-20",
          label: "10-20元",
        },
        {
          value: "20-50",
          label: "20-50元",
        },
        {
          value: "50-100",
          label: "50-100元",
        },
        {
          value: "100-200",
          label: "100-200元",
        },
        {
          value: "300-400",
          label: "300-400元",
        },
        {
          value: "400-500",
          label: "400-500元",
        },
        {
          value: "500-999999",
          label: "500元以上",
        },
      ],
      pickerShow: false,
      pickerType: "",
      sortordName: "默认",
      priceName: "默认",
    };
  },
  mounted() {
    // http("api.php", {});
    this.queryClassify();
    // 推荐商品
    let search = this.$route.query.search;
    this.getProductList(search);
  },
  // watch: {
  //   $route: {
  //     handler: function (val, oldVal) {
  //       this.queryClassify();
  //     },
  //     // 深度观察监听
  //     deep: true,
  //   },
  // },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        // this.queryClassify();
        if (val.query.search) {
          let search = this.$route.query.search;
          this.recommendList = [];
          this.getProductList(search);
          this.h5More = "";
        }
        if (val.query.allshop) {
          this.getProductList();
        }
        if (val.query.reset) {
          this.startNum = 0;
          this.recommendList = [];
          this.getProductList();
          this.h5More = "";
        }
        if (val.query.id) {
          if (!this.layoutPcMi) {
            this.queryClassShop(val.query.id);
            this.h5More = "";
          }
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
  methods: {
    onConfirm(value, index) {
      this.pickerShow = false;
      if (this.pickerType == "2") {
        this.priceChange(value.value, index);
        this.priceName = value.label;
      } else {
        this.sortChange(index, value.value);
        this.sortordName = value.label;
      }
    },
    onChange(picker, value, index) {
      // Toast(`当前值：${value}, 当前索引：${index}`);
    },
    onCancel() {
      // Toast("取消");
    },
    // 跳转
    to(url) {
      console.log("sss");
      this.$router.push(url);
    },
    resetCondition() {
      this.classShopActive = "All";
      this.priceScreen.forEach((item) => {
        item.checked = false;
      });
      this.sortord.forEach((item) => {
        item.checked = false;
      });
      if (JSON.stringify(this.$route.query) == "{}") {
        this.recommendList = [];
        this.getProductList();
      } else {
        this.$router.replace("/classificationGoods");
        this.getProductList();
      }
    },
    toContrast(data) {
      console.log(data);
      let list = JSON.parse(localStorage.getItem("contrastList")) || [];
      let id = [];
      if (list.length > 0) {
        list.forEach((item) => {
          id.push(item.id);
        });
        id = id.join(",");
      }
      if (list.length == 4 && id.indexOf(data.id) == -1) {
        list[3] = data;
      } else if (list.length < 4 && id.indexOf(data.id) == -1) {
        list.push(data);
      }
      localStorage.setItem("contrastList", JSON.stringify(list));
      this.$router.push("/goodsContrast");
    },
    menuOpen(i) {
      this.classificationList.forEach((item, index) => {
        if (i == index) {
          this.height = 500;
          // item.checked = !item.checked;
          this.$set(item, "checked", !item.checked);
          // if(item.child.length > 0) {
          //   item.child[0].checked = true
          // }
          if (item.checked) {
            let data = this.classificationList[index];
            this.classifyName = data.name;
            this.classifyId = data.id;
            this.queryClassShop(data.id);
          } else {
            item.checked = false;
            if (item.child.length > 0) {
              item.child.forEach((item2) => {
                item2.checked = false;
              });
            }
          }
        } else {
          item.checked = false;
          if (item.child.length > 0) {
            item.child.forEach((item2) => {
              // console.log(item2.checked);
              item2.checked = false;
            });
          }
        }
      });
    },

    menuChild(index, index2) {
      console.log(index2);
      this.classificationList[index].child.forEach((item, i) => {
        item.checked = i == index2;
      });
    },
    // 收藏
    collect(id) {
      http("column.php", {
        fun: "addColumnCollect",
        type: "c",
        module: "goods",
        bind_id: id,
      }).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: "收藏成功",
            type: "success",
          });
          this.$emit("collectChange");
        }
      });
    },
    rouGoodsCon() {
      this.$router.push("/goodsContrast");
    },
    handleNodeClick(data) {
      this.classifyName = data.name;
      this.classifyId = data.id;
      this.queryClassShop(data.id);
    },
    goodMouseOver(index) {
      // this.hoverBtn = true;
      this.productList[index].hover = true;
    },
    goodMouseLeave(index) {
      // this.hoverBtn = false;
      this.productList[index].hover = false;
    },
    rouGoodDetail(id) {
      this.$router.push("/detail?id=" + id);
    },
    // 获取商品分类
    queryClassify() {
      let that = this;
      that.classificationList = [];
      let id = that.$route.query.id;

      http("goods.php", {
        fun: "getGoodsType",
      }).then((res) => {
        if (res.code == 0) {
          that.classificationList = res.data;
          that.classificationList2 = that.classificationList.slice(
            0,
            that.endSlice
          );
          // let list = res.data;
          // if (id) {
          //   that.queryClassShop(id);
          // } else {
          //   that.queryClassShop(list[0].id);
          // }
          // that.classifyId = list[0].id;
          // if (that.classifyName == "") {
          //   that.classifyName = list[0].name;
          // }

          // list.forEach((item) => {
          //   if (id) {
          //     if (item.id == id) {
          //       // item.checked = true
          //       that.$set(item, "checked", true);
          //       that.height = 500;
          //     }
          //   } else {
          //     that.$set(item, "checked", false);
          //     // item.checked = false;
          //   }
          //   if (item.child.length > 0) {
          //     item.child.forEach((item2) => {
          //       item2.checked = false;
          //     });
          //   }
          //   that.classificationList.push({ ...item, label: item.name });
          // });
        }
      });
    },
    // 根据商品分类查询商品
    queryClassShop(id, title) {
      this.startNum = 0;
      if (this.layoutPcMi) {
        this.$router.replace(
          `/classificationGoods?id=${id}&classtitle=${title}`
        );
      }
      let that = this;
      that.loading = true;
      that.recommendList = [];
      that.requestParams = {};
      that.reset();
      that.goodsTypeId = id;
      http("goods.php", {
        fun: "getGoodsEx",
        goods_type_id: id,
        // goods_type: id,
        start_num: this.startNum,
        get_num: this.getNum,
      })
        .then((res) => {
          if (res.code == 0) {
            console.log(res);
            that.loading = false;
            that.recommendList = res.data;
            // that.productList.forEach((item) => {
            //   that.$set(item, "hover", false);
            // });
          }
        })
        .catch((err) => {
          console.log(err);
          that.loading = false;
          this.$message.error("请求超时");
        });
    },

    screen() {
      this.loading = true;
      let { minPrice, maxPrice } = this;
      http("goods.php", {
        fun: "getGoodsEx",
        // goods_type_id: this.classifyId,
        goods_type: this.classifyId,
        start_num: this.startNum,
        get_num: this.getNum,
        sort: this.sortVal,
        min_price: minPrice,
        max_price: maxPrice,
      }).then((res) => {
        if (res.code == 0) {
          this.productList = res.data;
          this.productList.forEach((item) => {
            this.$set(item, "hover", false);
          });
          this.loading = false;
        }
      });
    },

    // sortChange(e) {
    //   this.loading = true;
    //   http("goods.php", {
    //     fun: "getGoodsEx",
    //     goods_type_id: this.classifyId,
    //     start_num: 0,
    //     get_num: 10,
    //     sort: this.sortVal,
    //   }).then((res) => {
    //     if (res.code == 0) {
    //       this.productList = res.data;
    //       this.productList.forEach((item) => {
    //         this.$set(item, "hover", false);
    //       });
    //       this.loading = false;
    //     }
    //   });
    // },
    sortChange(index, val) {
      this.loading = true;
      this.startNum = 0;
      this.recommendList = [];
      let sort = "";
      let sort_by = "";
      if (val) {
        let arr = val.split("-");
        sort = arr[0];
        sort_by = arr[1];
      }
      this.sortord.forEach((item, i) => {
        item.checked = i == index;
      });
      http("goods.php", {
        fun: "getGoodsEx",
        start_num: this.startNum,
        get_num: this.getNum,
        // goods_tag: this.goodsTypeId ? "" : "latest",
        // goods_type_id: this.goodsTypeId,
        goods_type: this.goodsTypeId,
        sort_by,
        sort,
        ...this.requestParams,
      }).then((res) => {
        if (res.code == 0) {
          this.recommendList = res.data;

          this.loading = false;
        }
      });
    },
    priceScreenChange(e) {
      console.log(e);
      let arr = e.split("-");
      let minPrice = arr[0];
      let maxPrice = arr[1];
      this.loading = true;
      http("goods.php", {
        fun: "getGoodsEx",
        // goods_type_id: this.classifyId,
        goods_type: this.classifyId,
        start_num: this.startNum,
        get_num: this.getNum,
        sort: this.sortVal,
        min_price: minPrice,
        max_price: maxPrice,
      }).then((res) => {
        if (res.code == 0) {
          this.productList = res.data;
          this.productList.forEach((item) => {
            this.$set(item, "hover", false);
          });
          this.loading = false;
        }
      });
    },

    // 获取商品列表
    getProductList(name) {
      this.loading = true;
      let route = this.$route.query;

      let minPrice = "";
      let maxPrice = "";
      if (route.price) {
        if (route.price == "2000") {
          minPrice = "2000";
          maxPrice = "999999";
        } else {
          let arr = route.price.split("-");
          minPrice = arr[0];
          maxPrice = arr[1];
        }
      }
      http("goods.php", {
        fun: "getGoodsEx",
        // is_baseinfo: "0",
        start_num: this.startNum,
        get_num: this.getNum,
        // goods_tag: route.id ? "" : "latest",
        // goods_tag,
        min_price: minPrice,
        max_price: maxPrice,
        goods_name: name || "",
        goods_type_id: route.id ? route.id : "",
        // goods_type: route.id ? route.id : "",
        brand_name: route.brandname ? route.brandname : "",
      }).then((res) => {
        if (res.code == 0) {
          this.recommendList = res.data;

          this.loading = false;
        }
      });
    },

    // 价格筛选
    priceChange(e, index) {
      this.loading = true;
      this.startNum = 0;
      this.recommendList = [];
      if (this.$route.query.price) {
        this.$router.replace("/classificationGoods");
      }
      let arr = e.split("-");
      let minPrice = arr[0];
      let maxPrice = arr[1];
      this.priceScreen.forEach((item, i) => {
        item.checked = i == index;
      });
      let params = {
        fun: "getGoodsEx",
        start_num: this.startNum,
        get_num: this.getNum,
        // goods_tag: this.goodsTypeId ? "" : "latest",
        min_price: minPrice,
        max_price: maxPrice,
        goods_type_id: this.goodsTypeId,
        // goods_type: this.goodsTypeId,
      };
      this.requestParams = params;
      http("goods.php", params).then((res) => {
        if (res.code == 0) {
          this.recommendList = res.data;
          this.loading = false;
        }
      });
    },
    // 重置
    reset() {
      this.sortord.forEach((item) => {
        item.checked = false;
      });
      this.priceScreen.forEach((item) => {
        item.checked = false;
      });
    },

    loadMore() {
      this.endSlice += 30;
      this.classificationList2 = this.classificationList.slice(
        0,
        this.endSlice
      );
    },

    pagingBtn(type) {
      if (!this.layoutPcMi) {
        Toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: "加载中",
        });
      }
      if (type == "next") {
        if (this.layoutPcMi) {
          if (this.recommendList.length == 0) return;
        }
        this.startNum += this.getNum;
      } else {
        if (this.layoutPcMi) {
          if (type == "prev" && this.startNum == 0) return;
        }
        this.startNum -= this.getNum;
      }

      this.loading = true;
      if (this.layoutPcMi) {
        this.recommendList = [];
      }
      let route = this.$route.query;

      http("goods.php", {
        fun: "getGoodsEx",
        // is_baseinfo: "0",
        start_num: this.startNum,
        get_num: this.getNum,
        // goods_tag: route.id ? "" : "latest",
        // goods_tag,
        // min_price: minPrice,
        // max_price: maxPrice,
        goods_name: route.search || "",
        goods_type_id: route.id ? route.id : "",
        // goods_type: route.id ? route.id : "",
        brand_name: route.brandname ? route.brandname : "",
      }).then((res) => {
        Toast.clear();
        if (res.code == 0) {
          if (this.layoutPcMi) {
            this.recommendList = res.data;
            this.loading = false;
          } else {
            console.log(res.data);
            if (res.data.length == 0) {
              this.h5More = "noMore";
              Toast("没有更多数据了");
            }
            this.recommendList = [...this.recommendList, ...res.data];
          }
        }
      });
    },
    load() {
      if (this.h5More == "noMore") return;

      if (this.loading) return;
      this.pagingBtn("next");
      setTimeout(() => {
        this.loading = false;
      }, 2000);
    },
    onLoad() {
      console.log("ss");
      for (let i = 0; i < 30; i++) {
        this.list.push(this.list.length + 1);
      }

      // 加载状态结束
      this.loading2 = false;
      return;
      // this.loading2 = true;
      this.finished = false;
      // setTimeout(() => {
      if (this.refreshing) {
        // this.list = [];
        this.refreshing = false;
      }

      // for (let i = 0; i < 20; i++) {
      //   this.list.push(this.list.length + 1);
      // }
      this.loading2 = false;

      // if (this.list.length >= 40) {
      //   this.finished = true;
      // }
      // }, 1000);
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading2 = true;
      this.onLoad();
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/scss/index";

:deep(.el-loading-mask) {
  top: 100px;
}

.h5-breadcrumb {
  display: flex;
  font-size: 13px;
  margin-bottom: 20px;
  padding: 0 10px;
  div {
    &:nth-child(2) {
      margin: 0 10px;
    }
    &:last-child {
      color: #f30240;
    }
  }
}

.classification-goods {
  width: 1200px;
  margin: 0 auto;

  // @include body;
  .classification-location {
    display: flex;
    font-size: 11px;
    margin: 23px 0 8px;
    border-bottom: 1px solid #c51323;
    padding-bottom: 9px;

    div {
      &:nth-child(2) {
        color: #757373;
        margin: 0 20px;
        cursor: pointer;
      }
    }
  }

  .classification-container {
    display: flex;

    .left-list {
      border: 1px solid #ccc;
      width: 204px;
      margin-right: 10px;

      .left-item {
        padding: 0 15px;

        margin-bottom: 5px;

        &:last-child {
          margin-bottom: 0;
        }

        .classify-title {
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #666;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }

          img {
            min-width: 16px;
            height: 16px;
            margin-right: 5px;
          }
        }

        .classify-child {
          border-bottom: 1px dashed #dcdcdc;

          div {
            font-size: 12px;
            cursor: pointer;
            color: #757373;
            margin-left: 20px;
            padding: 6px 0;

            &:hover {
              color: rgb(197, 19, 35);
            }
          }
        }
      }
    }

    .right-content {
      flex: 1;

      .centerlist {
        border: 1px solid #ccc;

        .center-screen {
          display: flex;
          flex-wrap: wrap;
          font-size: 14px;
          border-bottom: 1px solid #ddd;

          &:last-child {
            border: 0;
          }

          .center-screen-title {
            padding: 10px 0;
            background-color: #f2f2f2;
            font-weight: bold;
            width: 105px;
            text-align: center;
            // margin-right: 10px;
          }

          .center-screen-item {
            font-size: 12px;
            margin-left: 10px;
            cursor: pointer;
            color: #757373;
            padding: 10px 3px;

            &:hover {
              color: rgb(197, 19, 35);
            }
          }

          .center-screen-price {
            padding: 7px 3px;
            font-size: 12px;
            margin-left: 10px;

            input {
              width: 50px;
              // height: 20px;
            }

            span {
              margin: 0 4px;
            }

            div {
              display: inline-block;
              border: 1px solid #ddd;
              padding: 3px 10px 3px;
              cursor: pointer;
              color: #666;
              font-size: 10px;
              margin-top: -3px;
              margin-left: 5px;
              background: #f0f0f0;
            }
          }
        }

        .sort-screen {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          font-size: 14px;
          border-bottom: 1px solid #ddd;

          &:last-child {
            border: 0;
          }

          .sort-screen-title {
            padding: 10px 0;
            background-color: #f2f2f2;
            font-weight: bold;
            width: 105px;
            text-align: center;
            // margin-right: 10px;
          }

          .sort-item {
            border: 1px solid #999999;
            line-height: 20px;
            margin-top: 5px;
            margin-left: 10px;
            background: white;
            width: 60px;
            cursor: pointer;
            font-size: 12px;
            text-decoration: none;
            text-align: center;
            color: #757373;

            &:hover {
              opacity: 0.5;
            }
          }
        }
      }
      .paging-content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .paging-left {
          border: 1px solid #999999;
          line-height: 25px;
          margin-top: 5px;
          margin-left: 10px;
          background: white;
          width: 70px;
          cursor: pointer;
          font-size: 12px;
          text-decoration: none;
          text-align: center;
          color: #757373;
        }
      }

      .paging {
        display: flex;
        justify-content: flex-end;

        div {
          border: 1px solid #999999;
          line-height: 20px;
          margin-top: 5px;
          margin-left: 10px;
          background: white;
          width: 60px;
          cursor: pointer;
          font-size: 12px;
          text-decoration: none;
          text-align: center;
          color: #757373;
        }
      }

      .product-list {
        margin-top: 15px;
        display: flex;
        flex-wrap: wrap;

        .product-item {
          width: 178px;
          // height: 265px;
          // padding: 9px 5px;
          background: #fff;
          text-align: center;
          font-size: 12px;
          padding: 9px 0;
          border-radius: 10px;
          cursor: pointer;
          margin: 0px 23px 20px 0px;

          &:nth-child(5n + 5) {
            margin-right: 0;
          }

          &:hover {
            background: #f2f2f2;
          }

          img {
            width: 160px;
            height: 160px;
            display: block;
            margin: 0 auto;
          }

          .product-content {
            background: #fff;
            width: 160px;
            margin: 0 auto;

            div {
              padding-top: 10px;

              &:nth-child(1) {
                color: #757373;
                /*1. 先强制一行内显示文本*/
                white-space: nowrap;
                /*2. 超出的部分隐藏*/
                overflow: hidden;
                /*3. 文字用省略号替代超出的部分*/
                text-overflow: ellipsis;

                &:hover {
                  text-decoration: underline;
                }
              }

              &:nth-child(2) {
                color: #999;
              }

              &:nth-child(3) {
                color: #999;

                span {
                  color: #c51323;
                }
              }
            }
          }
        }
      }
    }
  }

  .content {
    display: flex;

    .left-content {
      width: 25%;

      .left-menu {
        background: #fff;
        margin-bottom: 20px;

        // text-align: center;
        .menu-item {
          padding: 10px;
          padding-left: 0;
          cursor: pointer;

          font-size: 14px;
          border-bottom: 1px solid #e5e5e5;
          max-height: 0;
          overflow: hidden;
          transition: max-height 0.2s ease-out;

          .menu-item1 {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px;
          }

          .menu-item2 {
            margin-top: 20px;
            margin-left: 40px;

            div {
              margin-bottom: 10px;
              border-bottom: 1px dashed #e4e4e4;
              padding-bottom: 10px;

              &:last-child {
                border: 0;
                padding-bottom: 0;
              }
            }
          }
        }
      }

      .recommendList {
        display: flex;

        .recommended-goods {
          display: flex;
          width: 300px;
          min-width: 300px;

          img {
            width: 30%;
            margin-right: 20px;
          }

          div {
            @include flexCenter(
              $direction: column,
              $justify: space-between,
              $align: unset
            );
          }
        }
      }
    }

    .right-content {
      width: 75%;
      box-sizing: border-box;
      padding: 0 20px;

      .screen-price {
        display: flex;

        div {
          width: 70px;
        }
      }

      .goods-flex {
        display: flex;
        flex-wrap: wrap;

        .good-item {
          width: 33.33%;
          // margin-right: 20px;
          padding: 10px;
          text-align: center;
          background: #fff;
          cursor: pointer;
          box-sizing: border-box;
          position: relative;

          // margin-bottom: 20px;
          .el-button-add-cart {
            position: absolute;
            left: calc(50% - 60px);
          }

          .img {
            position: relative;

            img {
              width: 100%;
              height: 260px;
            }

            .hover-btn {
              position: absolute;
              top: 50%;
              left: calc(50% - 60px);
              display: flex;

              div {
                @include circular($border: none);
                background-color: white;
                @include flexCenter;
              }

              div:first-child {
                margin-right: 20px;
              }
            }

            .good-collect {
              &:hover {
                background: rgb(255, 218, 0);
                color: #000;
              }

              .el-icon-sort {
                transform: rotateZ(90deg);
              }
            }
          }

          &:hover {
            box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.1);

            .img-bg {
              position: absolute;
              width: 100%;
              height: 100%;
              background-color: rgba(255, 255, 255, 0.2);
            }
          }

          .price {
            color: #f30240;
            font-size: 18px;
            padding-bottom: 20px;
            font-weight: bold;
          }

          .evaluation i {
            color: #d2d2d2;
          }

          .add-cat {
            :deep(.el-button) {
              background-color: rgb(255, 218, 0);
              color: #fff;
              border-color: rgb(255, 218, 0);
            }
          }
        }
      }

      .goods-flex-ml {
        @extend .goods-flex;
        grid-template-columns: repeat(auto-fit, 40%) !important;
      }
    }
  }
}

.h5-category-product {
  -webkit-overflow-scrolling: touch;
  .h5-screen-type {
    font-size: 12px;
    padding: 0 10px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;

    .screen-type-item {
      display: flex;
      border: 1px solid #e3e3e3;
      width: 45%;
      .screen-label {
        background-color: #eee;
        padding: 5px;
        white-space: nowrap;
      }
      .screen-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0 5px;
      }
    }
  }

  .h5-product-list {
    display: flex;
    flex-wrap: wrap;
    padding: 0 5px;
    .h5-product-item {
      width: 50%;
      padding: 0 5px;
      box-sizing: border-box;
      text-align: center;
      margin-bottom: 20px;
      .h5-product-content {
        border: 1px solid #eee;
        padding-bottom: 10px;

        .h5-productname {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          color: #444;
          font-size: 15px;
          margin-top: 10px;
          padding: 0 2px;
        }
        .h5-price {
          color: red;
          font-size: 14px;
          span {
            color: #999;
            text-decoration: line-through;
          }
        }
        img {
          width: 100%;
          height: 163px;
        }
      }
    }
  }
}
</style>

<style scoped>
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 400px;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
